export const environment = {
  production: true,
  appVersion: '2.49.10-2c7601e1',
  checkVersion: true,
  builtAppHash: '2c7601e11c686e08f00ac301a749a7caf5c07eea',
  centrifugoWebsocketUrl: 'wss://centri.zumti.com/connection/websocket',
  jitsiurl: 'meetx.turkpages.com',
  webSiteUrl: 'https://web.marketup.ai/',
  apiUrl: 'https://api.marketup.ai/',
  natsUrl: 'wss://centri.zumti.com',
  googleCientId: '572001134774-bveghvbh9jda001oge723ujj45jbrihq.apps.googleusercontent.com',
  livekitUrl:   'wss://live.verirobot.com',
  customerName: 'marketupai',
  s3AccountUrl: 'https://marketupaicom.s3.eu-central-1.amazonaws.com/v2/',
};